<template>
    <div class="">
      <img src="@/assets/img/2.png" alt="" class="img" />
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {
        };
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    }
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.img{
  width: 100%;
  height: 100%;
}

</style>